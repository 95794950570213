import React from 'react';
import ReactDOM from 'react-dom';
import { SpeedInsights } from "@vercel/speed-insights/react"
import './styles.css';

// React component for the navigation bar
const Navbar = () => (
  <nav style={styles.navbar}>
    <div style={styles.navContent}>
      <div style={styles.logoContainer}>
        <img src="https://via.placeholder.com/100x50" alt="Logo" style={styles.logo} />
        <span style={styles.logoText}>TruckLeadPro</span>
      </div>
      <div style={styles.navLinks}>
        <a href="#" style={styles.navLink}>Home</a>
        <a href="#" style={styles.navLink}>About</a>
        <a href="#" style={styles.navLink}>Services</a>
        <a href="#" style={styles.navLink}>Contact</a>
      </div>
    </div>
  </nav>
);

// React component for the header section
const Header = () => (
  <header style={styles.header}>
    <div style={styles.headerContent}>
      <h1 style={styles.headerTitle}>Trucking Leads to Drive Your Business Forward</h1>
      <p style={styles.headerSubtitle}>Connecting trucking companies with verified, high-quality leads.</p>
      <button style={styles.proposalButton}>Get a Proposal</button>
    </div>
  </header>
);

// React component for the main content section
const Main = () => (
  <main style={styles.main}>
    <section style={styles.section}>
      <h2 style={styles.sectionTitle}>Why Choose TruckLeadPro?</h2>
      <p style={styles.sectionText}>
        At TruckLeadPro, we specialize in providing top-quality trucking leads to help your business thrive. Our leads are carefully curated to ensure you connect with the right clients and expand your operations effectively.
      </p>
    </section>
    <section style={styles.section}>
      <h2 style={styles.sectionTitle}>Our Services</h2>
      <div style={styles.serviceContainer}>
        <div style={styles.serviceBox}>
          <img src="https://via.placeholder.com/100" alt="Service 1" style={styles.serviceImage} />
          <h3 style={styles.serviceTitle}>Lead Generation</h3>
          <p style={styles.serviceText}>Customized lead generation services tailored for the trucking industry.</p>
        </div>
        <div style={styles.serviceBox}>
          <img src="https://via.placeholder.com/100" alt="Service 2" style={styles.serviceImage} />
          <h3 style={styles.serviceTitle}>Fleet Expansion</h3>
          <p style={styles.serviceText}>Helping you expand your fleet with quality leads and new opportunities.</p>
        </div>
        <div style={styles.serviceBox}>
          <img src="https://via.placeholder.com/100" alt="Service 3" style={styles.serviceImage} />
          <h3 style={styles.serviceTitle}>Consulting</h3>
          <p style={styles.serviceText}>Expert consulting to optimize your trucking business strategies.</p>
        </div>
      </div>
    </section>
  </main>
);

// React component for the footer section
const Footer = () => (
  <footer style={styles.footer}>
    <p style={styles.footerText}>&copy; 2024 TruckLeadPro. All rights reserved.</p>
    <div style={styles.socialIcons}>
      <a href="#" style={styles.socialLink}>Facebook</a>
      <a href="#" style={styles.socialLink}>Twitter</a>
      <a href="#" style={styles.socialLink}>LinkedIn</a>
    </div>
  </footer>
);

// Main React App component
const App = () => (
  <div style={styles.container}>
    <Navbar />
    <Header />
    <Main />
    <SpeedInsights /> 
    <Footer />
  </div>
);

// Inline styles for the React components
const styles = {
  container: {
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#f4f4f4',
    color: '#333',
  },
  navbar: {
    backgroundColor: '#0070f3',
    padding: '10px 20px',
    display: 'flex',
    justifyContent: 'center',
  },
  navContent: {
    width: '100%',
    maxWidth: '1200px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    height: '40px',
    marginRight: '10px',
  },
  logoText: {
    color: '#fff',
    fontSize: '20px',
    fontWeight: 'bold',
  },
  navLinks: {
    display: 'flex',
    gap: '15px',
  },
  navLink: {
    color: '#fff',
    textDecoration: 'none',
    fontSize: '16px',
  },
  header: {
    background: 'url("https://via.placeholder.com/1920x600") no-repeat center center/cover',
    padding: '100px 20px',
    color: '#fff',
    textAlign: 'center',
  },
  headerContent: {
    maxWidth: '800px',
    margin: '0 auto',
  },
  headerTitle: {
    fontSize: '36px',
    marginBottom: '20px',
  },
  headerSubtitle: {
    fontSize: '18px',
    marginBottom: '30px',
  },
  proposalButton: {
    backgroundColor: '#ff6600',
    color: '#fff',
    padding: '15px 30px',
    border: 'none',
    borderRadius: '5px',
    fontSize: '16px',
    cursor: 'pointer',
  },
  main: {
    padding: '40px 20px',
    textAlign: 'center',
  },
  section: {
    backgroundColor: '#fff',
    padding: '30px',
    margin: '20px 0',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  sectionTitle: {
    fontSize: '28px',
    marginBottom: '15px',
  },
  sectionText: {
    fontSize: '16px',
    color: '#555',
  },
  serviceContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    marginTop: '20px',
  },
  serviceBox: {
    width: '30%',
    backgroundColor: '#f9f9f9',
    padding: '20px',
    borderRadius: '8px',
    marginBottom: '20px',
    textAlign: 'center',
  },
  serviceImage: {
    height: '100px',
    marginBottom: '15px',
  },
  serviceTitle: {
    fontSize: '20px',
    marginBottom: '10px',
  },
  serviceText: {
    fontSize: '14px',
    color: '#777',
  },
  footer: {
    backgroundColor: '#333',
    color: '#fff',
    padding: '20px 0',
    textAlign: 'center',
  },
  footerText: {
    marginBottom: '10px',
  },
  socialIcons: {
    display: 'flex',
    justifyContent: 'center',
    gap: '15px',
  },
  socialLink: {
    color: '#fff',
    textDecoration: 'none',
    fontSize: '16px',
  },
};

// Render the App component into the root element
ReactDOM.render(<App />, document.getElementById('root'));
